import { Instance, SnapshotIn, types } from 'mobx-state-tree'

import { QResolveLocationResolveLocation } from '../graph/generated'
import { formatAddress } from '../helpers/formats'
import { GeoPoint } from '../helpers/gps'
import { BaseModel } from '../models/BaseModel'
import { AddressSearchResult } from '../segments/map/hooks/useAddressSearch'

const numberRx = /[0-9]/

export const LocationModel = BaseModel.named('Location')
  .props({
    latitude: types.maybe(types.number),
    longitude: types.maybe(types.number),
    searchValue: types.optional(types.string, ''),
    address: types.maybe(types.string),
    zoneDeliveryTimeDeviation: types.optional(types.number, 0),
  })
  .volatile(() => ({
    branchId: null as MaybeID,
    isOpen: true,
    isResolved: false,
    isStreetAddress: false,
    inDeliveryLocation: false,
    isResolving: false,
    hasResolutionResult: false,
    initialSearchAddress: null as Maybe<string>,
    forcedAddress: null as Maybe<string>,
    resolvedFor: null as Maybe<string>,
  }))
  .views(self => ({
    get isGeolocationAvailable() {
      return Boolean(navigator.geolocation)
    },
    get hasLocation() {
      return self.latitude !== undefined || self.longitude !== undefined
    },
    get hasAddress() {
      return Boolean(self.address && self.address.length > 0)
    },
    get hasSearchValue() {
      return self.searchValue.trim().length > 0
    },
    get isDeliveryAddress() {
      return self.isResolved && self.isStreetAddress && self.inDeliveryLocation
    },
    get geoPoint() {
      if (this.hasLocation) {
        return {
          latitude: self.latitude!,
          longitude: self.longitude!,
        }
      }
      return null
    },
    get isLockedForChanges() {
      return Boolean(self.forcedAddress)
    },
  }))
  .actions(self => ({
    setLocation({ latitude, longitude }: GeoPoint) {
      self.latitude = latitude
      self.longitude = longitude
    },
    setIsResolving(resolving: boolean) {
      if (resolving !== self.isResolving) {
        self.isResolving = resolving
        self.log(`resolving has ${resolving ? 'started' : 'ended'}`)
      }
    },
    setSearchValue(search: string) {
      if (self.isLockedForChanges) {
        return
      }

      self.searchValue = search
    },
    setInitialSearchAddress(address: string) {
      if (self.isLockedForChanges) {
        return
      }

      self.searchValue = address
      self.initialSearchAddress = address
    },
    setAddress(address: string) {
      self.address = address
      self.log(`set address to ${address}`)
    },
    clearAddress() {
      self.address = undefined
      self.log('cleared address')
    },
  }))
  .actions(self => ({
    setResolutionResult(
      result: QResolveLocationResolveLocation,
      rawInput: Maybe<string>,
    ) {
      if (self.isLockedForChanges && self.forcedAddress !== rawInput) {
        return
      }

      self.branchId = result.companyBranch ? result.companyBranch.id : null
      self.isResolved = result.isResolved
      self.isStreetAddress = result.isValidAddress
      self.inDeliveryLocation = result.inDeliveryLocation
      self.resolvedFor = rawInput
      self.zoneDeliveryTimeDeviation = result.zone?.deliveryTimeDeviation ?? 0

      if (result.finalAddress) {
        const address = formatAddress(result.finalAddress)

        // * simple check if address contains number to filter out wrong results for a whole city and similar
        const addressContainsNumber = numberRx.test(address)

        // * address with a venue (eg. hotel name or ATM label) (produced by the Nominatim) should always be final, even without a house number
        const addressHasVenue = Boolean(result.finalAddress.venue)

        if (addressContainsNumber || addressHasVenue) {
          self.setAddress(address)
        } else {
          self.clearAddress()
          self.isStreetAddress = false
        }
      }

      self.hasResolutionResult = true

      if (!(result.isResolved && result.gpsCoordinates)) {
        self.log('not resolved')
        return
      }

      self.setLocation(result.gpsCoordinates)
      self.log(
        `resolved location for branch: ${self.branchId}, zone: ${
          result.zone ? result.zone.id : null
        }`,
      )
    },
    setSearchResult(result: AddressSearchResult) {
      if (self.isLockedForChanges) {
        return
      }

      result.gps && self.setLocation(result.gps)
      self.setAddress(result.address)
      self.setIsResolving(false)
    },
    setAsNotResolved() {
      self.hasResolutionResult = true
      self.isResolved = false
      self.setIsResolving(false)
    },
    setAsNotAddress() {
      self.hasResolutionResult = true
      self.isResolved = true
      self.isStreetAddress = false
      self.setIsResolving(false)
    },
    resetResolution() {
      self.branchId = null
      self.isResolved = false
      self.isStreetAddress = false
      self.inDeliveryLocation = false
      self.hasResolutionResult = false
      self.clearAddress()
    },
    forceResolve(address: string) {
      self.forcedAddress = address
    },
  }))

export interface TLocationModel extends Instance<typeof LocationModel> {}
export interface TLocationModelProps extends SnapshotIn<typeof LocationModel> {}
