import { format, formatWithOptions } from 'date-fns/fp'
import { cs, de, enUS, pl, sk } from 'date-fns/locale'

import { useRoot } from '../app/useRoot'
import { FJsonAddressFragment } from '../graph/generated'

const locales = {
  en: enUS,
  cs,
  sk,
  pl,
  de,
}

const getLocale = (localeKey: string): Locale => {
  return locales[localeKey] ?? enUS
}

export const formatTime = format('H:mm')

export const formatAddress = ({
  city,
  state,
  venue,
  street,
  houseNumber,
}: FJsonAddressFragment) => {
  const hasCity = Boolean(city)
  const hasVenue = Boolean(venue)
  const hasState = Boolean(state)
  const hasStreet = Boolean(street)
  const hasHouseNumber = Boolean(houseNumber)

  const streetWithHouseNumber = hasHouseNumber
    ? `${street} ${houseNumber}`
    : `${street}`

  if (hasCity && hasVenue) {
    return hasStreet
      ? `${venue}, ${streetWithHouseNumber}, ${city}`
      : `${venue}, ${city}`
  }

  if (hasCity && hasStreet) {
    return `${streetWithHouseNumber}, ${city}`
  }

  if (hasCity && hasHouseNumber) {
    return `${city} ${houseNumber}`
  }

  if (hasState) {
    return `${state}`
  }

  if (hasCity) {
    return `${city}`
  }

  return ``
}

export const dateFormats = {
  weekdayMonthDay: `iiii, MMMM d`,
  weekdayHourMinute: `iiii, H:mm`,
  hourMinute: `H:mm`,
}

export const useFormatDate = () => {
  const { user } = useRoot()

  const formatDate = (date: Date, format: string) => {
    return formatWithOptions({ locale: getLocale(user.language) })(format)(date)
  }

  return formatDate
}
