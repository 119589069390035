import { Instance, SnapshotIn } from 'mobx-state-tree'

import { BaseModel } from './BaseModel'

export const MenuModel = BaseModel.named('Menu')
  .volatile(() => ({
    rawSearchValue: '',
  }))
  .views(self => ({
    get searchValue() {
      return self.rawSearchValue.toLocaleLowerCase()
    },
  }))
  .actions(self => ({
    changeSearchValue(updatedValue: string) {
      self.rawSearchValue = updatedValue
    },
  }))

export interface TMenuModel extends Instance<typeof MenuModel> {}
export interface TMenuModelProps extends SnapshotIn<typeof MenuModel> {}
