import { autorun } from 'mobx'
import env from 'penv.macro'
import React from 'react'

import { useRoot } from '../app/useRoot'

export const PageTitle: React.FC = () => {
  const { business } = useRoot()

  const prefix = env({ dev: '[DEV]', local: '[LOCAL]' }) || ''
  const suffix = env({ prod: getProdSuffix() }) || ''

  React.useEffect(() => {
    if (!document) {
      return
    }
    return autorun(() => {
      const { branch } = business
      if (branch) {
        document.title = `${prefix} ${branch.brand.name} ${suffix}`.trim()
      } else {
        document.title = `${prefix} ${suffix}`.trim()
      }
    })
  }, [business, prefix, suffix])

  return null
}

function getProdSuffix() {
  const { hostname } = window.location
  if (hostname.includes('doveze.cz')) {
    return '- Doveze.cz'
  } else if (hostname.includes('dovezie.sk')) {
    return '- Dovezie.sk'
  } else if (hostname.includes('speedlo.')) {
    return '- speɘdlo'
  }
  return ''
}
