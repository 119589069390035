import { Sentry } from '@speedlo/sentry'
import localforage from 'localforage'
import {
  applySnapshot,
  getSnapshot,
  getType,
  IStateTreeNode,
  onSnapshot,
} from 'mobx-state-tree'

import { appConfig } from '../config'
import { logPersist } from './logger'

const { persistenceKey } = appConfig

export function persistModel(model: IStateTreeNode) {
  const modelName = getType(model).name
  const stateKey = `${persistenceKey}_${modelName}`

  const writeState = snapshot => {
    return localforage.setItem(stateKey, snapshot).catch(err => {
      Sentry.withScope(scope => {
        scope.setTag('persistence', stateKey)
        scope.setExtras({
          snapshot,
        })
        Sentry.captureException(err)
      })
    })
  }

  return localforage
    .getItem(stateKey)
    .catch(err => {
      localforage.removeItem(stateKey)
      Sentry.withScope(scope => {
        scope.setTag('persistence', stateKey)
        Sentry.captureException(err)
      })
    })
    .then(state => {
      if (state !== null) {
        applySnapshot(model, state)
        logPersist('restored state %s', stateKey)
      } else {
        writeState(getSnapshot(model)).then(() => {
          logPersist('initialized state %s', stateKey)
        })
      }

      return onSnapshot(model, writeState)
    })
}
