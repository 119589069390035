import { useAutorun } from '@speedlo/hooks'
import { Sentry } from '@speedlo/sentry'
import React from 'react'

import { useRoot } from './useRoot'

export const IdentifyGuest: React.FC = () => {
  const { auth } = useRoot()
  useAutorun(() => {
    const id = auth.userId!
    const token = auth.token
    Sentry.configureScope(scope => {
      scope.setUser({ id, username: 'GUEST' })
      scope.setExtra('token', token)
    })
  })
  return null
}
