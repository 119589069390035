import { detectLanguage } from '@speedlo/ui'
import { Instance, SnapshotIn, types } from 'mobx-state-tree'

import { appConfig } from '../config'
import { BaseModel } from './BaseModel'

export const UserModel = BaseModel.named('User')
  .props({
    selectedLanguage: types.maybe(types.string),
    rememberForm: types.maybeNull(types.boolean),
    showAppleBanner: types.optional(types.boolean, true),
  })
  .volatile(() => ({
    closingTimeAlertHidden: false,
    orderLimitAlertHidden: true,
    languageActivated: false,
    memoryApplied: false,
  }))
  .views(self => ({
    get language(): string {
      return (
        self.selectedLanguage ||
        self.root.business.defaultLanguage ||
        detectLanguage(appConfig.supportedLanguages, appConfig.fallbackLanguage)
      )
    },
    get shouldRememberForm() {
      return self.rememberForm !== false
    },
  }))
  .actions(self => ({
    selectLanguage(lang: string) {
      if (lang !== self.selectedLanguage) {
        self.languageActivated = false
      }
      self.selectedLanguage = lang
      self.log('selected language %s', lang)
    },
    activateLanguage() {
      self.languageActivated = true
      self.log('activated language %s', self.language)
    },
    showClosingTime() {
      self.closingTimeAlertHidden = false
    },
    hideClosingTime() {
      self.closingTimeAlertHidden = true
    },
    showOrderLimit() {
      self.orderLimitAlertHidden = false
    },
    hideOrderLimit() {
      self.orderLimitAlertHidden = true
    },
    setFormRememberYes() {
      self.rememberForm = true
    },
    setFormRememberNo() {
      self.rememberForm = false
    },
    setMemoryApplied() {
      self.memoryApplied = true
    },
    hideAppleBanner() {
      self.showAppleBanner = false
    },
  }))

export interface TUserModel extends Instance<typeof UserModel> {}
export interface TUserModelProps extends SnapshotIn<typeof UserModel> {}
