import { useObserver } from 'mobx-react-lite'
import React from 'react'
import { BrowserRouter } from 'react-router-dom'

import { useRoot } from './useRoot'

export const Router: React.FC = ({ children }) => {
  const { business } = useRoot()
  return useObserver(() => (
    <BrowserRouter
      basename={business.routerBasePath}
      key={business.routerBasePath}
    >
      {children}
    </BrowserRouter>
  ))
}
