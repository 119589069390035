import debug from 'debug'
import { getPath, getRoot, getType, types } from 'mobx-state-tree'

import { TRootModel } from './RootModel'

export const BaseModel = types.model().views(self => ({
  get log() {
    const name = getPath(self).trim() || getType(self).name
    // if (process.env.NODE_ENV === 'test') {
    //   return (...args) => console.log(`model${name}`, ...args)
    // }
    return debug(`doveze:model${name}`)
  },
  get root(): TRootModel {
    return getRoot(self)
  },
}))
