import { Instance, SnapshotIn } from 'mobx-state-tree'

import { BaseModel } from './BaseModel'

export const CartModel = BaseModel.named('Cart')
  .volatile(() => ({
    withGoods: false,
    withPresence: false,
    orderIsSubmitting: false,
  }))
  .actions(self => ({
    updateDisplay(isMobile: boolean, showGoods: boolean) {
      self.withGoods = !isMobile || showGoods
      self.withPresence = isMobile ? !showGoods : true
    },
    setOrderSubmitting(state: boolean) {
      self.orderIsSubmitting = state
    },
  }))

export interface TCartModel extends Instance<typeof CartModel> {}
export interface TCartModelProps extends SnapshotIn<typeof CartModel> {}
