import { when } from 'mobx'
import React from 'react'

export function useSuspenseWhen(predicate: () => boolean) {
  const [suspend, setSuspend] = React.useState<Maybe<Promise<any>>>(null)
  const thrown = React.useRef(false)
  const resolverRef = React.useRef<Maybe<AnyFunction>>()

  React.useEffect(() => {
    if (predicate() === true && suspend === null) {
      setSuspend(
        new Promise(resolve => {
          resolverRef.current = resolve
        }),
      )
      when(
        () => !predicate(),
        () => {
          if (resolverRef.current) {
            resolverRef.current()
            resolverRef.current = null
            setSuspend(null)
          }
        },
      )
    }
  }, [predicate, suspend])

  if (suspend !== null && !thrown.current) {
    thrown.current = true
    throw suspend
  }
}
