import { Instance, SnapshotIn } from 'mobx-state-tree'

import { BaseModel } from './BaseModel'

export const ConfigModel = BaseModel.named('Config').props({
  orderItemsMax: 50,
})

export interface TConfigModel extends Instance<typeof ConfigModel> {}
export interface TConfigModelProps extends SnapshotIn<typeof ConfigModel> {}
