import { disableBatching } from '@speedlo/graphql'
import { Sentry } from '@speedlo/sentry'
import { observer } from 'mobx-react-lite'
import React from 'react'

import { appConfig } from '../config'
import { useQBusinessLookup } from '../graph/generated'
import { useRoot } from './useRoot'

export const BusinessLoader = observer(() => {
  const root = useRoot()
  const { business } = useRoot()

  const { getStash } = useQBusinessLookup(
    {
      url: window.location.href,
    },
    {
      skip: !root.initialized,
      fetchPolicy: 'cache-first',
      ...disableBatching,
    },
  )

  const lookup = getStash(data => data.lookup)

  React.useEffect(() => {
    if (business.isValid || !lookup) {
      return
    }

    if (!lookup.branch || lookup.redirectUrl) {
      Sentry.withScope(scope => {
        scope.setExtras({
          url: window.location.href,
          redirectUrl: lookup.redirectUrl,
          requestedCode: business.requestedCode,
        })

        Sentry.captureMessage('No business found')
      })

      window.location.replace(lookup.redirectUrl ?? appConfig.redirectOnFail)
      return
    }

    business.updateFromLookup(lookup)
    business.log(
      'identified branch %s, brand %s; company %s, isBrand: %s',
      business.branchId,
      business.brandId,
      business.companyId,
      business.isBrand,
    )
  })

  return null
})
