import './Modal.css'

import React from 'react'
import {
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  ModalProps,
} from 'reactstrap'

interface IProps extends ModalProps {
  isOpen: boolean
  headerText: string
  onClose?(): void
}

export const AlertModal: React.FC<IProps> = ({
  isOpen,
  headerText,
  onClose,
  children,
  ...modalProps
}) => (
  <Modal
    isOpen={isOpen}
    toggle={onClose}
    className="modal__container"
    {...modalProps}
    data-testid="alertModal"
  >
    <ModalHeader tag={Container} toggle={onClose} className="modal__header">
      <span>{headerText}</span>
    </ModalHeader>
    <ModalBody className="modal__body">
      <div className="modal__body__content">{children}</div>
    </ModalBody>
  </Modal>
)
