import { useRouteMatch } from '@speedlo/hooks'
import React from 'react'

import { useOrderModel } from '../hooks/useOrderModel'

export const ResetOrderAtRoot: React.FC = () => {
  const match = useRouteMatch('/')
  const orderModel = useOrderModel()

  React.useEffect(() => {
    if (match && match.isExact && orderModel.hasOrder) {
      orderModel.orderCancel()
      orderModel.changeCartItem(null)
      orderModel.addToCart(null)
    }
  })

  return null
}
