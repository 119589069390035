import { differenceInDays } from 'date-fns'
import { Instance, SnapshotIn, types } from 'mobx-state-tree'

import { BaseModel } from './BaseModel'

const daysHidden = 15
const daysReminder = 90

export const BannerModel = BaseModel.named('Banner')
  .props({
    branchId: types.identifier,
    closedAt: types.maybe(types.Date),
    installedAt: types.maybe(types.Date),
  })
  .views(self => ({
    get shouldBeVisible(): boolean {
      if (self.root.business.isMobileApp) {
        return false
      }
      if (
        self.closedAt &&
        differenceInDays(new Date(), self.closedAt) > daysHidden
      ) {
        return true
      }
      if (
        self.installedAt &&
        differenceInDays(new Date(), self.installedAt) > daysReminder
      ) {
        return true
      }
      // none of these has been set yet, banner should be visible
      return !Boolean(self.closedAt || self.installedAt)
    },
  }))
  .actions(self => ({
    setInstalledNow() {
      self.installedAt = new Date()
    },
    setClosedNow() {
      self.closedAt = new Date()
    },
  }))

export interface TBannerModel extends Instance<typeof BannerModel> {}
export interface TBannerModelProps extends SnapshotIn<typeof BannerModel> {}
