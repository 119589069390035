import { Trans } from '@lingui/macro'
import { ApolloDefender, isNetworkUnreachableError } from '@speedlo/graphql'
import { useRouterContext } from '@speedlo/hooks'
import { flushSentry, Sentry } from '@speedlo/sentry'
import { SillyErrorBoundary } from '@speedlo/tools'
import { isApolloError } from 'apollo-client'
import React from 'react'

import { useRoot } from '../app/useRoot'
import { useAlertModal } from '../hooks/useAlertModal'
import { i18n } from '../i18n'
import { AlertModal } from './AlertModal'
import { PrimaryButton, SecondaryButton } from './Buttons'

interface IProps {
  prod: boolean
}

export type ErrorKind = 'none' | 'serviceUnreachable' | 'otherError'

const makeError = (message: string) =>
  `${message} ${i18n.t`Our team was notified about the issue.`}`

export const ErrorContainer: React.FC<IProps> = ({
  children,
  prod = false,
}) => {
  const [errorKind, setErrorKind] = React.useState<ErrorKind>('none')
  const [alertModal, makeAlert] = useAlertModal()

  const onRetry = React.useCallback(() => {
    setErrorKind('none')
  }, [])

  const onUnhandledError = React.useCallback((error: Error) => {
    if (isChunkError(error)) {
      Sentry.withScope(scope => {
        scope.setLevel(Sentry.Severity.Debug)
        Sentry.captureException(error)
      })
      flushSentry().then(() => window.location.reload())
      return
    }
    if (isApolloError(error) && isNetworkUnreachableError(error)) {
      setErrorKind('serviceUnreachable')
      return
    }
    Sentry.captureException(error)
    setErrorKind('otherError')
  }, [])

  const onNetworkError = React.useCallback(
    (error: Error, isUnreachable: boolean) => {
      if (isUnreachable) {
        setErrorKind('serviceUnreachable')
      } else {
        console.error(error)
        makeAlert(
          i18n.t`Service problem`,
          <div>
            {makeError(
              i18n.t`Unexpected error occured in communication with service.`,
            )}
          </div>,
        )
      }
    },
    [makeAlert],
  )

  const onOperationError = React.useCallback(
    error => {
      console.error(error)
      makeAlert(
        i18n.t`Communication problem`,
        <div>
          {makeError(i18n.t`Unexpected error occured while sending a request.`)}
        </div>,
      )
    },
    [makeAlert],
  )

  const onUserErrors = React.useCallback(
    (messages: ReadonlyArray<string>) => {
      makeAlert(
        i18n.t`Problems encountered`,
        <div>
          {messages.map(msg => (
            <div key={msg}>{msg}</div>
          ))}
        </div>,
      )
    },
    [makeAlert],
  )

  const { history } = useRouterContext()
  const [modalOpen, setModalOpen] = React.useState(true)

  const onFeedback = React.useCallback(() => {
    setModalOpen(false)
    showFeedback()
  }, [])

  const { business } = useRoot()

  const onRestart = React.useCallback(() => {
    setModalOpen(false)
    history.replace(business.routerBasePath)
    window.location.reload()
  }, [business.routerBasePath, history])

  const render = () => {
    switch (errorKind) {
      case 'serviceUnreachable': {
        return (
          <AlertModal
            isOpen={modalOpen}
            headerText={i18n.t`Service is temporarily unavailable`}
          >
            <Trans>
              Please try again in moment. We apologize for inconvenience.
            </Trans>
            <PrimaryButton onClick={onRetry}>
              <Trans>Try now</Trans>
            </PrimaryButton>
          </AlertModal>
        )
      }
      case 'otherError': {
        return (
          <AlertModal
            isOpen={modalOpen}
            headerText={i18n.t`Unexpected error has occurred`}
          >
            <Trans>
              If you like, please fill out feedback form so we can fix the
              problem faster. Restart the application otherwise.
            </Trans>
            <br />
            <PrimaryButton onClick={onRestart}>
              <Trans>Restart application</Trans>
            </PrimaryButton>
            <SecondaryButton onClick={onFeedback}>
              <Trans>Send feedback</Trans>
            </SecondaryButton>
          </AlertModal>
        )
      }
      default: {
        return children
      }
    }
  }

  let errorGuard

  if (prod) {
    errorGuard = (
      <SillyErrorBoundary
        onError={error => {
          onUnhandledError(error)
        }}
      >
        {render()}
      </SillyErrorBoundary>
    )
  } else {
    const DeveloperErrorBoundary = require('react-error-guard/lib/DeveloperErrorBoundary')
      .default
    errorGuard = <DeveloperErrorBoundary>{render()}</DeveloperErrorBoundary>
  }

  return (
    <ApolloDefender
      onNetworkError={onNetworkError}
      onOperationError={onOperationError}
      onUserErrors={onUserErrors}
    >
      <>
        {alertModal}
        {errorGuard}
      </>
    </ApolloDefender>
  )
}

function isChunkError(error: Error) {
  return error.message && error.message.indexOf('Loading chunk') >= 0
}

function showFeedback() {
  Sentry.showReportDialog({
    labelClose: i18n.t`Close`,
    title: i18n.t`Unexpected problem occurred`,
  })
}
