import localforage from 'localforage'
import React from 'react'

import { SecondaryButton } from '../common/Buttons'
import { memoryKey } from '../segments/orderForm/OrderFormMemory'
import { useRoot } from './useRoot'

interface IProps {}

export const ResetPersistenceButton: React.FC<IProps> = props => {
  const root = useRoot()
  if (process.env.REACT_APP_STAGE === 'prod') {
    return null
  }
  return (
    <SecondaryButton
      onClick={() => {
        root.reset()
        localforage.removeItem(memoryKey)
        setTimeout(() => window.location.reload(), 500)
      }}
    >
      Reset uživ. dat [DEV]
    </SecondaryButton>
  )
}
