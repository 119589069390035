import { observable } from 'mobx'
import {
  applySnapshot,
  getSnapshot,
  Instance,
  SnapshotIn,
  types,
} from 'mobx-state-tree'

import { persistModel } from '../helpers/persistModel'
import { AuthModel } from './AuthModel'
import { BaseModel } from './BaseModel'
import { BusinessModel } from './BusinessModel'
import { CartModel } from './CartModel'
import { ConfigModel } from './ConfigModel'
import { LocationModel } from './LocationModel'
import { MenuModel } from './MenuModel'
import { OrderModel } from './OrderModel'
import { UserModel } from './UserModel'

export const RootModel = BaseModel.named('Root')
  .props({
    auth: types.optional(AuthModel, {}),
    business: types.optional(BusinessModel, {}),
    cart: types.optional(CartModel, {}),
    config: types.optional(ConfigModel, {}),
    order: types.optional(OrderModel, {}),
    location: types.optional(LocationModel, {}),
    user: types.optional(UserModel, {}),
    menu: types.optional(MenuModel, {}),
  })
  .views(self => ({
    get persistedModels() {
      return [self.auth, self.user, self.business, self.location, self.order]
    },
  }))
  .extend(self => {
    let initialSnapshot
    const initialized = observable.box(false)
    return {
      views: {
        get initialized() {
          return initialized.get()
        },
      },
      actions: {
        afterCreate() {
          const { business, ...snapshot } = getSnapshot(self)
          initialSnapshot = snapshot
        },
        reset() {
          applySnapshot(self, initialSnapshot)
        },
        init: async () => {
          await Promise.all(self.persistedModels.map(persistModel))
          initialSnapshot.business = getSnapshot(self.business)
          self.log('initialized')
          initialized.set(true)
        },
      },
    }
  })

export interface TRootModel extends Instance<typeof RootModel> {}
export interface TRootModelProps extends SnapshotIn<typeof RootModel> {}
