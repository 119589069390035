import React from 'react'

import { AlertModal } from '../common/AlertModal'

export function useAlertModal() {
  const [alertModal, setAlertModal] = React.useState<ReactNode>(null)

  const makeAlert = React.useCallback(
    (header: string, body: ReactNode | string) => {
      setAlertModal(
        React.createElement(
          AlertModal,
          {
            isOpen: true,
            onClose: () => setAlertModal(null),
            headerText: header,
          },
          body,
        ),
      )
    },
    [],
  )

  return [alertModal, makeAlert] as [ReactNode, typeof makeAlert]
}
