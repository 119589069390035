import './enableDebug'

import { ApolloProvider } from '@speedlo/graphql'
import { useSentryInit } from '@speedlo/sentry'
import { updateWorker } from '@speedlo/tools'
import React from 'react'
import ReactDOM from 'react-dom'

import App from './app/App'
import { Router } from './app/Router'
import { RootProvider } from './app/useRoot'
import { appConfig } from './config'
import { createApolloClient } from './graph/createClient'
import { i18n, I18nManager } from './i18n'
import { RootModel } from './models/RootModel'

const Main = () => {
  useSentryInit(appConfig.sentryDSN)

  const rootInit = React.useRef<Promise<void>>()
  const [root] = React.useState(() => {
    const rootInst = RootModel.create({})
    rootInit.current = rootInst.init()
    return rootInst
  })

  const [client] = React.useState(() => {
    return createApolloClient(root, rootInit.current!)
  })

  return (
    <RootProvider value={root}>
      <ApolloProvider client={client}>
        <I18nManager>
          <Router>
            <App />
          </Router>
        </I18nManager>
      </ApolloProvider>
    </RootProvider>
  )
}

ReactDOM.render(<Main />, document.getElementById('root'))

updateWorker.register({
  autoUpdate: true,
  onUpdate: (sw: ServiceWorker) => {
    setTimeout(() => {
      if (
        window.confirm(
          i18n.t`New version has been found. Do you want to reload application to apply it now?`,
        )
      ) {
        sw.postMessage('apply-update')
      }
    }, 5000)
  },
})

if (navigator.serviceWorker) {
  navigator.serviceWorker.addEventListener('message', event => {
    if (event.data === 'update-applied') {
      window.location.reload()
    }
  })
}
