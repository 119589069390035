import { useRouterContext, useWhen } from '@speedlo/hooks'
import { when } from 'mobx'
import React from 'react'
import { Route } from 'react-router-dom'

import { MapContext } from '../components/Map/MapContext'
import { GeoPoint, gpsToLatLng } from '../helpers/gps'
import { useOrderCreate } from '../segments/map/hooks/useOrderCreate'
import { useRoot } from './useRoot'

interface IProps {}

export const CypressHelper: React.FC<IProps> = props => {
  if (process.env.REACT_APP_STAGE === 'prod') {
    return null
  }

  /* eslint-disable react-hooks/rules-of-hooks */

  const { user, business, location, order } = useRoot()
  const { history } = useRouterContext()
  const { createPickupOrder, createDeliveryOrder } = useOrderCreate(() => {
    history.push('/order')
  })

  useWhen(
    () => user.root.initialized,
    () => {
      if (window.location.search.includes('useDevLang')) {
        user.selectLanguage('en-dev')
      }
    },
  )

  const businessValid = new Promise(resolve => {
    when(() => business.isValid, resolve)
  })

  const createDeliveryOrderWithGps = (gps: GeoPoint, branchId: ID) => {
    location.setLocation(gps)
    order.setBranch(branchId)
    return businessValid.then(() => createDeliveryOrder())
  }

  const createPickupOrderForBranch = (branchId: ID) => {
    return businessValid.then(() => createPickupOrder(branchId))
  }

  const mapModel = React.useContext(MapContext)

  const changeMapLocation = (
    gps: GeoPoint,
    zoom: number = mapModel?.zoom ?? 0,
  ) => {
    mapModel?.map?.panTo(gpsToLatLng(gps), { animate: false })
    mapModel?.map?.zoomTo(zoom, { animate: false })
  }

  Object.assign(window, {
    createPickupOrderForBranch,
    createDeliveryOrderWithGps,
    changeMapLocation,
  })

  return <Route path="/cypress" component={() => null} />
}
