import { Sentry } from '@speedlo/sentry'
import { Instance, SnapshotIn, types } from 'mobx-state-tree'

import { BaseModel } from './BaseModel'

export const AuthModel = BaseModel.named('Auth')
  .props({
    token: types.maybeNull(types.string),
    userId: types.maybeNull(types.string),
  })
  .views(self => ({
    get isValid() {
      return self.token !== null
    },
  }))
  .actions(self => ({
    authenticate(token: string, userId: string) {
      self.token = token
      self.userId = userId
      Sentry.addBreadcrumb({
        category: 'Auth',
        level: Sentry.Severity.Info,
        data: {
          userId,
          token,
        },
      })
    },
  }))

export interface TAuthModel extends Instance<typeof AuthModel> {}
export interface TAuthModelProps extends SnapshotIn<typeof AuthModel> {}
