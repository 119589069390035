import { disableBatching } from '@speedlo/graphql'
import Color from 'color'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { ThemeContext, ThemeProvider } from 'styled-components/macro'

import {
  QThemeStyleStyles,
  QThemeStyleVariables,
  useQThemeStyle,
} from '../graph/generated'
import { useRoot } from './useRoot'

interface IProps {
  children: ReactNode[]
}

const ThemeSwitchProvider = observer<IProps>(({ children }) => {
  const { order, business } = useRoot()

  const variables: QThemeStyleVariables = {
    brandId: business.isBrand && !order.hasOrder ? business.brandId : undefined,
    branchId:
      business.isBrand && !order.hasOrder
        ? undefined
        : order.branchId || business.branchId,
  }

  const { data } = useQThemeStyle(variables, {
    skip: !business.isValid && !order.hasOrder,
    fetchPolicy: 'cache-first',
    ...disableBatching,
  })

  const theme = React.useMemo(
    () => getTheme(data && data.theme && data.theme.styles),
    [data],
  )

  return (
    <ThemeProvider theme={theme}>
      <>{children}</>
    </ThemeProvider>
  )
})

export function useTheme() {
  return React.useContext(ThemeContext) as TTheme
}

export function getTheme(styles?: QThemeStyleStyles) {
  // Fallback colors
  const defaultPrimary = '#54984a'
  const defaultSecondary = '#99230e'
  const defaultTertiary = '#d9a700'

  const defaultTheme = {
    primary: defaultPrimary,
    secondary: defaultSecondary,
    tertiary: defaultTertiary,
    primaryHover: calcHoverColor(defaultPrimary),
    secondaryHover: calcHoverColor(defaultPrimary),
    tertiaryHover: calcHoverColor(defaultTertiary),
    tertiaryText: 'white',
    unselected: '#E5E5E5',
    unselectedHover: '#EDEDED',
    unselectedText: '#5A5A5A',
    storeLogo: null,
    storeLogoMobile: null,
    backgroundCart: undefined,
  }

  if (styles) {
    return {
      ...defaultTheme,
      primary: styles.active || defaultPrimary,
      secondary: styles.main || defaultSecondary,
      tertiary: styles.mainSecondary || defaultTertiary,
      primaryHover: styles.active && calcHoverColor(styles.active),
      secondaryHover: styles.main && calcHoverColor(styles.main),
      tertiaryHover:
        styles.mainSecondary && calcHoverColor(styles.mainSecondary),
      storeLogo: styles.storeLogo,
      storeLogoMobile: styles.storeLogoMobile,
      backgroundCart: styles.backgroundCart,
    }
  }

  return defaultTheme
}

function calcHoverColor(inputColor: string) {
  return Color(inputColor)
    .lighten(0.2)
    .hsl()
    .string()
}

export type TTheme = ReturnType<typeof getTheme>

export default ThemeSwitchProvider
