import { Sentry } from '@speedlo/sentry'
import React from 'react'

import { useRoot } from '../../../app/useRoot'
import {
  useMDeliveryOrderCreate,
  useMPickupOrderCreate,
} from '../../../graph/generated'

export function useOrderCreate(onConfirm: () => void) {
  const { order, location, business } = useRoot()

  const [deliveryOrderCreate] = useMDeliveryOrderCreate()
  const [pickupOrderCreate] = useMPickupOrderCreate()

  const createDeliveryOrder = React.useCallback(async () => {
    if (!location.geoPoint) {
      return
    }
    if (location.branchId) {
      order.setBranch(location.branchId)
    }
    const result = await deliveryOrderCreate({
      gps: location.geoPoint,
      orderOrigin: business.orderOrigin,
      address: location.address,
      ...business.brandOrBranch,
    })
    if (result) {
      if (location.branchId && result.order.branch.id !== location.branchId) {
        Sentry.withScope(scope => {
          scope.setExtras({
            requestedBranchId: location.branchId,
            createdOrderId: result.order.id,
            createdBranchId: result.order.branch.id,
          })
          Sentry.captureMessage(
            'Delivery order was created for different branch.',
          )
        })
      }
      order.orderCreated(result.order)
      onConfirm()
    }
  }, [business, deliveryOrderCreate, location, onConfirm, order])

  const createPickupOrder = async (branchId: ID) => {
    // it's necessary to set asap so correct data are displayed further
    order.setBranch(branchId)
    const result = await pickupOrderCreate({
      branchId,
      orderOrigin: business.orderOrigin,
    })
    if (result) {
      if (result.order.branch.id !== branchId) {
        Sentry.withScope(scope => {
          scope.setExtras({
            requestedBranchId: branchId,
            createdOrderId: result.order.id,
            createdBranchId: result.order.branch.id,
          })
          Sentry.captureMessage(
            'Pickup order was created for different branch.',
          )
        })
      }
      order.orderCreated(result.order)
      onConfirm()
    }
  }

  return { createDeliveryOrder, createPickupOrder }
}
