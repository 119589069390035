import React from 'react'
import { Button, ButtonProps } from 'reactstrap'
import styled, { css } from 'styled-components/macro'

import { TTheme } from '../app/ThemeSwitchProvider'
import { mediaDesktop, mediaMobile } from '../common/Responsive'

export const PrimaryButton = styled(Button)`
  font-size: 1.1rem;
  font-variant-caps: all-small-caps;
  background-color: ${themeProp('secondary')};
  border-color: ${themeProp('secondary')};
  &:hover {
    background-color: ${themeProp('secondaryHover')};
    border-color: ${themeProp('secondaryHover')};
  }
  &:active {
    background-color: #262626 !important;
  }
`

export const SecondaryButton = styled(Button)`
  font-size: 1.1rem;
  font-variant-caps: all-small-caps;
  background-color: ${themeProp('tertiary')};
  border-color: ${themeProp('tertiary')};
  &:hover {
    background-color: ${themeProp('tertiaryHover')};
    border-color: ${themeProp('tertiaryHover')};
  }
`

interface ISelectButtonThemed {
  selected?: boolean
  theme: TTheme
}

export const SelectButtonThemed = styled(
  ({ selected, theme, ...props }: ButtonProps & ISelectButtonThemed) => (
    <Button {...props} />
  ),
)`
  ${({ theme, selected }: ISelectButtonThemed) => css`
    font-size: 1.1rem;
    font-variant-caps: all-small-caps;
    ${mediaDesktop`
      background-color: ${selected ? theme.tertiary : theme.unselected}
    `};
    ${mediaMobile`
      background-color: ${selected ? theme.tertiary : 'white'}`}
      border-color: ${selected ? theme.tertiary : theme.unselected};
      color: ${selected ? theme.tertiaryText : theme.unselectedText};
      &:hover {
        background-color: ${
          selected ? theme.tertiaryHover : theme.unselectedHover
        };
        border-color: ${selected ? theme.tertiaryHover : theme.unselectedHover};
        color: ${selected ? theme.tertiaryText : theme.unselectedText};
      }
  `};
`

function themeProp(name: keyof TTheme) {
  return ({ theme }: { theme?: TTheme }) => theme![name] as string
}
