import React from 'react'

import { useSuspenseWhen } from './hooks/useSuspenseWhen'
import { useRoot } from './useRoot'

export const SuspendOnInit: React.FC<Required<SomeChildren>> = ({
  children,
}) => {
  const { business, user } = useRoot()

  const predicate = () => !(business.isValid && user.languageActivated)

  useSuspenseWhen(predicate)

  return <>{children}</>
}
