import React from 'react'
import ReactResponsive from 'react-responsive'
import { css } from 'styled-components/macro'

export const mobileBreakpoint = 767

export const Desktop = props => (
  <ReactResponsive {...props} minWidth={mobileBreakpoint} />
)
export const Mobile = props => (
  <ReactResponsive {...props} maxWidth={mobileBreakpoint} />
)

interface IResponsiveProps {
  desktop: string
  mobile: string
}

export const Responsive: React.FC<IResponsiveProps> = ({
  children,
  mobile,
  desktop,
}) => (
  <>
    <Desktop className={desktop} children={children} />
    <Mobile className={mobile} children={children} />
  </>
)

export const mediaDesktop = (...args) => css`
  @media (min-width: ${mobileBreakpoint / 16}em) {
    // @ts-ignore
    ${css(...args)};
  }
`

export const mediaMobile = (...args) => css`
  @media (max-width: ${mobileBreakpoint / 16}em) {
    // @ts-ignore
    ${css(...args)};
  }
`
