/**
 *  This file was generated via `npx @speedlo/config`.
 *  Do NOT alter this file – all changes will be overridden!
 */
import * as RM from 'ramda'

export { RM }

export const idProp = RM.prop('id')
export const idPropEq = RM.propEq('id')
