import {
  createAuthLink,
  createDateParserLink,
  createDefaultApolloClient,
  createLanguageLink,
  createLogLink,
  createNetworkLink,
  createOfflineLink,
  createRetryLink,
  fetchGuestToken,
} from '@speedlo/graphql'

import { appConfig } from '../config'
import { TRootModel } from '../models/RootModel'

export function createApolloClient(root: TRootModel, rootInit: Promise<void>) {
  const getToken = async () => {
    await rootInit
    if (root.auth.token) {
      return root.auth.token
    }
    const { token, userId } = await fetchGuestToken(appConfig.endpoint)
    root.auth.authenticate(token, userId)
    return token
  }

  const getLang = () => root.user.language

  return createDefaultApolloClient(
    createAuthLink({ getToken }),
    createLanguageLink(getLang),
    createLogLink({ enabled: process.env.REACT_APP_STAGE !== 'prod' }),
    createRetryLink(),
    createOfflineLink(),
    createDateParserLink(getResolvers()),
    createNetworkLink({ endpoint: appConfig.endpoint }),
  )
}

function getResolvers() {
  return {
    CompanyBranch: ['willOpenAt', 'willCloseAt'],
    BusinessHourInterval: ['start', 'end', 'openAt', 'closeAt'],
  }
}
