import React, { useContext } from 'react'

import { TRootModel } from '../models/RootModel'

export const rootContext = React.createContext<TRootModel | undefined>(
  undefined,
)

export const useRoot = () => {
  const root = useContext(rootContext)
  if (!root) {
    throw new Error('RootProvider missing in the tree')
  }
  return root
}

export const RootProvider = rootContext.Provider
