import { Catalog, setupI18n } from '@lingui/core'
import { I18nProvider } from '@lingui/react'
import { useAutorun } from '@speedlo/hooks'
import React from 'react'

import { useRoot } from './app/useRoot'
import { changeDFLocale } from './tools/datefns'

export const i18n = setupI18n()

export const I18nManager: React.FC = ({ children }) => {
  const root = useRoot()
  const { user } = root
  const [, forceUpdate] = React.useState(1)

  useAutorun(async () => {
    if (root.initialized && !user.languageActivated) {
      await loadCatalog(user.language)
      user.log(`loaded language "${user.language}" catalog`)
      i18n.activate(user.language)
      await changeDFLocale(user.language)
      user.activateLanguage()
      forceUpdate(i => i + 1)
    }
  })

  return <I18nProvider i18n={i18n}>{children}</I18nProvider>
}

async function loadCatalog(lang: string) {
  let catalog: Catalog
  if (process.env.NODE_ENV !== 'production') {
    // prettier-ignore
    catalog = await import(
      /* webpackMode: "lazy", webpackChunkName: "i18n-[index]" */
      `@lingui/loader!./locale/${lang}/messages.po`
    )
  } else {
    // prettier-ignore
    catalog = await import(
      /* webpackMode: "lazy", webpackChunkName: "i18n-[index]" */
      `./locale/${lang}/messages.js`
    )
  }
  i18n.load({ [lang]: catalog })
}
