import { LngLat } from 'mapbox-gl'

import { latLng } from '../components/Map/mapUtils'
import { FGeoPointFragment } from '../graph/generated'

export type GeoPoint = LegacyOmit<FGeoPointFragment, '__typename'>

export const gpsToLatLng = (gps: GeoPoint): LngLat =>
  latLng(Number(gps.latitude), Number(gps.longitude))

export const latLngToGps = (latLng: LngLat): GeoPoint => ({
  latitude: Number(latLng.lat),
  longitude: Number(latLng.lng),
})
