import { LngLat } from 'mapbox-gl'

export function latLng(latitude: number, longitude: number): LngLat {
  return new LngLat(longitude, latitude)
}

// @function createHTMLElement(tagName: String, className?: String, container?: HTMLElement): HTMLElement
// Creates an HTML element with `tagName`, sets its class to `className`, and optionally appends it to `container` element.
// taken from leaflet lib
export function createHTMLElement(
  tagName: keyof HTMLElementTagNameMap,
  className?: string,
  container?: HTMLElement,
) {
  const el = document.createElement(tagName)
  el.className = className || ''

  if (container) {
    container.appendChild(el)
  }

  return el
}
