import React from 'react'
import Spinner from 'react-spinkit'

export interface IProps {
  noMargin?: boolean
}

const Pacman: React.FC<IProps> = ({ noMargin = false }) => (
  <div
    style={{
      display: 'flex',
      alignSelf: 'center',
      justifyContent: 'center',
    }}
    data-testid="loader"
  >
    <Spinner name="pacman" style={noMargin ? {} : { marginTop: '45vh' }} />
  </div>
)

export default Pacman
