import * as DF from 'date-fns'
import * as DFP from 'date-fns/fp'

import { RM } from './ramda'

export { DF, DFP }

export let DFLocale: Locale

export const formatWithLocale = RM.curry((format: string, value: Date) =>
  DF.format(value, format, { locale: DFLocale }),
)

export async function changeDFLocale(lang: string) {
  let locale = lang
  if (lang === 'en' || lang === 'zu') {
    locale = 'en-US'
  }
  // prettier-ignore
  DFLocale = (await import(
    /* webpackMode: "lazy", webpackChunkName: "datefns" */
    `date-fns/locale/${locale}/index.js`
  )).default
}
