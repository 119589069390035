import { Instance, SnapshotIn, types } from 'mobx-state-tree'

import {
  AnalyticsCodeTypeEnum,
  FCompanyBranchCountry,
  FCompanyBranchFragment,
  OrderOriginEnum,
  QBusinessLookupLookup,
} from '../graph/generated'
import { BannerModel, TBannerModel } from './BannerModel'
import { BaseModel } from './BaseModel'

type TBranch = LegacyOmit<FCompanyBranchFragment, 'address'>

interface IVolatileProps {
  isBrand: boolean
  isMobileApp: boolean
  branch: Maybe<TBranch>
  country: Maybe<FCompanyBranchCountry>
  orderOrigin: OrderOriginEnum
  routerBasePath: string
  defaultLanguage: Maybe<string>
  googleAnalyticsCode?: Maybe<string>
}

export const BusinessModel = BaseModel.named('Business')
  .props({
    banners: types.map(BannerModel),
  })
  .volatile<IVolatileProps>(() => ({
    isBrand: false,
    isMobileApp: false,
    branch: null,
    country: null,
    orderOrigin: OrderOriginEnum.Web,
    routerBasePath: '',
    defaultLanguage: null,
    googleAnalyticsCode: undefined,
  }))
  .views(self => ({
    get branchId() {
      return self.branch && self.branch.id
    },
    get companyId() {
      return self.branch && self.branch.company.id
    },
    get brandId() {
      return self.branch && self.branch.brand.id
    },
    get brandOrBranch() {
      return {
        brandId: self.isBrand ? this.brandId : undefined,
        branchId: self.isBrand ? undefined : this.branchId,
      }
    },
    get isValid() {
      return self.branch !== null
    },
    get requestedCode() {
      return process.env.REACT_APP_BUSINESS_CODE || null
    },
  }))
  .actions(self => ({
    updateFromLookup(lookup: QBusinessLookupLookup) {
      const {
        branch,
        isBrand,
        analytics,
        isMobileApp,
        orderOrigin,
        knownBasePath,
      } = lookup

      if (!branch) {
        // handled in BusinessLoader.tsx
        return
      }

      self.isBrand = isBrand
      self.isMobileApp = isMobileApp
      self.branch = branch
      self.country = branch.address.country || null
      self.routerBasePath = knownBasePath
      self.orderOrigin = orderOrigin
      self.defaultLanguage = lookup.defaultLanguage.toLowerCase()

      const googleAnalytics = analytics.find(analytic => {
        return analytic.type.enum === AnalyticsCodeTypeEnum.GoogleAnalytics
      })

      self.googleAnalyticsCode = googleAnalytics?.code ?? null
    },
    ensureBannerForCurrentBranch(): TBannerModel {
      const branchId = self.branchId!
      if (!self.banners.has(branchId)) {
        self.banners.set(branchId, { branchId })
      }
      return self.banners.get(branchId)!
    },
  }))

export interface TBusinessModel extends Instance<typeof BusinessModel> {}
export interface TBusinessModelProps extends SnapshotIn<typeof BusinessModel> {}
