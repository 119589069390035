import { useAutorun } from '@speedlo/hooks'
import { Sentry } from '@speedlo/sentry'
import { useXFormCtx } from '@speedlo/xform'
import { debounce } from 'debounce'
import localforage from 'localforage'
import React from 'react'

import { useRoot } from '../../app/useRoot'
import { appConfig } from '../../config'
import { OrderFormValues } from './OrderFormSetup'

export const memoryKey = `${appConfig.persistenceKey}_orderForm`

const catchError = err => {
  Sentry.withScope(scope => {
    scope.setTag('persistence', memoryKey)
    Sentry.captureException(err)
  })
}

type TMemory = Pick<
  OrderFormValues,
  'firstname' | 'lastname' | 'email' | 'phone' | 'phonePrefix'
>

const writeMemory = debounce((memory: TMemory, done: () => void) => {
  localforage.setItem(memoryKey, memory)
  done()
}, 3000)

const readMemory = (): Promise<any> => {
  return localforage.getItem(memoryKey).catch(catchError)
}

export const OrderFormMemory = React.memo(() => {
  const { user } = useRoot()
  const xform = useXFormCtx<OrderFormValues>()

  const applyMemory = React.useCallback(
    (memory: OrderFormValues) => {
      if (user.memoryApplied) {
        return
      }

      // previously the note was part of the persisted state, so now as it was removed
      // don’t forget to ignore the older note if there was any
      const { note, ...memoryWithoutNote } = memory
      xform.api.mergeValues(memoryWithoutNote)

      Object.entries(memoryWithoutNote).forEach(([key, value]) => {
        if (value) {
          xform.state.touched.add(key)
        }
      })

      user.setMemoryApplied()
      user.log('applied order form memory')
    },
    [user, xform],
  )

  React.useEffect(() => {
    readMemory().then(memory => {
      if (memory) {
        applyMemory(memory)

        return
      }
    })
  }, [applyMemory])

  useAutorun(() => {
    if (!user.shouldRememberForm) {
      return
    }

    writeMemory(
      {
        firstname: xform.state.values.firstname,
        lastname: xform.state.values.lastname,
        email: xform.state.values.email,
        phonePrefix: xform.state.values.phonePrefix,
        phone: xform.state.values.phone,
      },
      () => {
        user.log('written order form memory')
      },
    )
  })

  return null
})
