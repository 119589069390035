import './App.css'

import React from 'react'

import { ErrorContainer } from '../common/ErrorContainer'
import Pacman from '../common/Pacman'
import { PageTitle } from '../common/PageTitle'
import { Desktop, Mobile } from '../common/Responsive'
import { AddSentryContext } from './AddSentryContext'
import { BusinessLoader } from './BusinessLoader'
import { CypressHelper } from './CypressHelper'
import { IdentifyGuest } from './IdentifyGuest'
import { ResetOrderAtRoot } from './ResetOrderAtRoot'
import { ResetPersistenceButton } from './ResetPersistenceButton'
import { SuspendOnInit } from './SuspendOnInit'
import ThemeSwitchProvider from './ThemeSwitchProvider'

const DesktopApp = React.lazy(() => import('./DesktopApp'))
const MobileApp = React.lazy(() => import('./MobileApp'))

const App: React.FC = () => (
  <ErrorContainer prod={process.env.NODE_ENV === 'production'}>
    <ThemeSwitchProvider>
      <BusinessLoader />
      <IdentifyGuest />
      <PageTitle />
      <AddSentryContext />
      <ResetOrderAtRoot />
      <CypressHelper />
      <React.Suspense fallback={<Pacman />}>
        <SuspendOnInit>
          <Desktop>
            <DesktopApp />
          </Desktop>
          <Mobile>
            <MobileApp />
          </Mobile>
          <ResetPersistenceButton />
        </SuspendOnInit>
      </React.Suspense>
    </ThemeSwitchProvider>
  </ErrorContainer>
)

export default App
