import { useSentryScope } from '@speedlo/sentry'
import { observer } from 'mobx-react-lite'

import { useOrderModel } from '../hooks/useOrderModel'

export const AddSentryContext = observer(() => {
  const scope = useSentryScope()
  const orderModel = useOrderModel()

  scope.setExtras({
    orderId: orderModel.orderId,
    orderBranchId: orderModel.branchId,
  })

  return null
})
