import { Instance, SnapshotIn, types } from 'mobx-state-tree'

import {
  DeliveryTypeEnum,
  FOrderCreateFragment,
  PaymentGateEnum,
} from '../graph/generated'
import { i18n } from '../i18n'
import { BaseModel } from './BaseModel'

const paymentMethodsWithTip = [
  PaymentGateEnum.Gopay,
  PaymentGateEnum.Payu,
  PaymentGateEnum.Csob,
]

export const OrderModel = BaseModel.named('Order')
  .props({
    branchId: types.maybeNull(types.string),
    orderId: types.maybeNull(types.string),
    orderMethod: DeliveryTypeEnum.Messenger,
    deliverAt: types.maybeNull(types.Date),
    tips: 0,
  })
  .volatile(() => ({
    isSubmitting: false,
    isCheckingPaymentStatus: false,
    hasUsedGoPay: false,
    submitErrors: null as Maybe<RoA<string>>,
    lastActiveRecipeId: null as MaybeID,
    timePickerOpen: false,
    addToCartRecipeId: null as MaybeID,
    changeCartItemId: null as MaybeID,
    isVamInitialized: false,
    paymentMethod: null as Maybe<PaymentGateEnum>,
  }))
  .views(self => ({
    get hasOrder() {
      return self.orderId !== null
    },
    get usingDeliveryOrderMethod() {
      return self.orderMethod === DeliveryTypeEnum.Messenger
    },
    get usingPickupOrderMethod() {
      return self.orderMethod === DeliveryTypeEnum.Pickup
    },
    get usingPaymentMethodWithTips() {
      return (
        self.paymentMethod && paymentMethodsWithTip.includes(self.paymentMethod)
      )
    },
    get correctedTip() {
      return this.usingPaymentMethodWithTips ? self.tips : 0
    },
    get hasErrors() {
      return Boolean(self.submitErrors && self.submitErrors.length > 0)
    },
    get defaultPhonePrefix(): string {
      if (self.root.business.isValid && self.root.business.country) {
        return self.root.business.country.phonePrefix
      }
      return ''
    },
    get disableInteraction() {
      return (
        self.isSubmitting || self.isCheckingPaymentStatus || self.hasUsedGoPay
      )
    },
    get isDelayed() {
      return self.deliverAt !== null
    },
    get orderMethodEnum() {
      return self.orderMethod as DeliveryTypeEnum
    },
  }))
  .actions(self => ({
    useOrderMethod(method: DeliveryTypeEnum) {
      self.orderMethod = method
    },
    setPaymentMethod(method: PaymentGateEnum) {
      self.paymentMethod = method
    },
    setBranch(branchId: ID) {
      self.branchId = branchId
    },
    orderCreated(result: FOrderCreateFragment) {
      self.orderId = result.id
      self.branchId = result.branch.id
      self.isCheckingPaymentStatus = false
      self.hasUsedGoPay = false
      self.deliverAt = null
      self.addToCartRecipeId = null
      self.changeCartItemId = null
      self.log('order %s created for branch %s', self.orderId, self.branchId)
    },
    orderPaid(orderId: ID) {
      if (self.orderId !== orderId) {
        self.orderId = orderId
        self.log('order %s replaced by %s due to successful payment status')
      }
    },
    orderCancel() {
      if (self.branchId || self.orderId) {
        self.log(
          'cancel order %s, was at branch %s',
          self.root.order.orderId,
          self.root.order.branchId,
        )
      }
      self.orderId = null
      self.branchId = null
    },
    submitStarted() {
      self.isSubmitting = true
    },
    submitFinished() {
      self.isSubmitting = false
    },
    submitFailed(errors?: ReadonlyArray<string>) {
      self.isSubmitting = false
      self.submitErrors = errors || [
        i18n.t`Error during sending order. Please try again later.`,
      ]
    },
    vamFailed() {
      self.submitErrors = [
        i18n.t`Internal error during sending order. Order can not be finished right now. Our team was notified and is working on fix. Thank you for your understanding.`,
      ]
    },
    clearErrors() {
      self.submitErrors = null
    },
    paymentStatusCheck(checking: boolean) {
      self.isCheckingPaymentStatus = checking
      self.hasUsedGoPay = true
    },
    setUsedRecipeId(recipeId: ID) {
      self.lastActiveRecipeId = recipeId
    },
    openTimePicker() {
      self.timePickerOpen = true
    },
    closeTimePicker() {
      self.timePickerOpen = false
    },
    setTips(tips: number) {
      self.tips = tips
    },
    setDeliverAt(when: Maybe<Date>) {
      self.deliverAt = when
    },
    addToCart(recipeId: MaybeID) {
      self.addToCartRecipeId = recipeId
    },
    changeCartItem(itemId: MaybeID) {
      self.changeCartItemId = itemId
    },
  }))
  .actions(self => ({
    initVam() {
      self.isVamInitialized = true
    },
  }))
  // for backward-compatibility with OrderFormMemory
  .props({
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
    phonePrefix: '',
    note: '',
  })

export interface TOrderModel extends Instance<typeof OrderModel> {}
export interface TOrderModelProps extends SnapshotIn<typeof OrderModel> {}
